import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { StaticQuery, graphql } from "gatsby";

function makingWorldsCourseFaculty() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMdx(
            sort: { fields: [frontmatter___title], order: DESC }
            filter: { frontmatter: { tags: { eq: "making_worlds_faculty" } } }
          ) {
            nodes {
              frontmatter {
                title
                headshot
              }
              body
            }
          }
        }
      `}
      render={(data) => (
        <Row className="convening-faculty">
          <h1>Course Faculty</h1>
          {data.allMdx.nodes.map((node, index) => (
            <Col key={index} xs={12}>
              <Row>
                <Col
                  xs={11}
                  md={4}
                  lg={3}
                  className="mb-3 mb-md-0 p-4 text-center"
                >
                  <img
                    src={node.frontmatter.headshot}
                    alt={`Headshot of ${node.frontmatter.title}`}
                    className="img-fluid img-rounded"
                  />
                </Col>
                <Col xs={11} md={8} lg={9} className="p-3">
                  <h5>{node.frontmatter.title}</h5>
                  <MDXRenderer>{node.body}</MDXRenderer>
                </Col>
                <hr />
              </Row>
            </Col>
          ))}
        </Row>
      )}
    />
  );
}

export default makingWorldsCourseFaculty;
