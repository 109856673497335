import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles/contentQuoteCenter.scss";

const ContentQuoteCenter = ({ quoteCenter, quoteAuthorCenter }) => {
  return (
    <div id="contentQuoteCenter">
      <Row className="align-items-center justify-content-center content-quote text-center">
        <Col lg={10} md={11} sm={11} className="order-md-first">
          <p className="contentQuoteCenter">"{quoteCenter}"</p>
          <p className="quote-author">— {quoteAuthorCenter}</p>
        </Col>
      </Row>
    </div>
  );
};

export default ContentQuoteCenter;
